import React, { useState } from "react";
import Slider from "@mui/material/Slider";
import "./Microsoft365Backup.css";
import Veeam365Contract from "./PriceFiles/Veeam365Contract.json";
import Veeam365ContractStudent from "./PriceFiles/Veeam365ContractStudent.json";
import Box from "@mui/material/Box";
import Veeam365 from "./PriceFiles/Veeam365.json";
import {
  Row,
  Col,
  Form,
  InputGroup,
  Card,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { InfoCircleFill } from "react-bootstrap-icons";
import VirtualDCS from "../src/Images/virtualDCS-logo.png";
import Veeam from "../src/Images/veeam.png";
import Microsoft365Pricing from './Microsoft365Pricing';
export const Microsoft365Backup = (props) => {
  const [contractUserValue, setContractUserValue] = useState("");
  const [contractStudentValue, setContractStudentValue] = useState(0);
  const [paygUserValue, setPaygUserValue] = useState(0);
  const [dataProtectValue, setDataProtectValue] = useState(0);
  const [isContract, setIsContract] = useState(true);
  const [isEducationalUser, setIsEducationalUser] = useState(false);
  const [userPrice, setUserPrice] = React.useState(0.0);
  const [studentPrice, setStudentPrice] = React.useState(0.0);
  const [averagePrice, setAveragePrice] = React.useState(0.0);
  const [total, setTotal] = React.useState(0.0);

  const clearData = () => {
    setContractUserValue(0);
    setContractStudentValue(0);
    setPaygUserValue(0);
    setDataProtectValue(0);
    setUserPrice(0);
    setStudentPrice(0);
    setAveragePrice(0);
    setTotal(0);
  };

  const calculatePrice = (
    userSliderValue,
    studentSliderValue,
    contractLength
  ) => {
    var userCost = 0;
    var studentCost = 0;
    var userSlideQuantity = userSliderValue;
    var studentSlideQuantity = studentSliderValue;
    var averageUserPrice = 0;
    var averageStudentPrice = 0;
    if (isContract) {
      if (isEducationalUser) {
        for (let index = 0; index < Veeam365Contract.DiskBand.length; index++) {
          const element = Veeam365Contract.DiskBand[index];
          if (userSlideQuantity <= JSON.parse(element.Start)) {
            userCost = userSlideQuantity * element.Price;
            averageUserPrice = element.Price;
            break;
          }
        }
        // if (userSlideQuantity === 0) {
        //   //setAmount(0);
        //   setTotal(0);
        // }
        // else {
        //var disc = (1 - (Number(contractLength) / 100));
        //var amount = Number(userCost / userSlideQuantity);
        //setAmount((amount * disc).toFixed(3));
        //userCost = (userCost * disc).toFixed(2);
        setUserPrice(averageUserPrice);
        // }

        for (
          let index = 0;
          index < Veeam365ContractStudent.DiskBand.length;
          index++
        ) {
          const element = Veeam365ContractStudent.DiskBand[index];
          if (studentSlideQuantity <= JSON.parse(element.Start)) {
            studentCost = studentSlideQuantity * element.Price;
            averageStudentPrice = element.Price;
            break;
          }
        }
        // if (studentSlideQuantity === 0) {
        //   //setAmount(0);
        //   setTotal(0);
        // }
        // else {
        //var disc = (1 - (Number(contractLength) / 100));
        //var amount = Number(studentCost / studentSlideQuantity);
        //setAmount((amount * disc).toFixed(3));

        //studentCost = (studentCost * disc).toFixed(2);
        //console.log(studentCost);
        setStudentPrice(averageStudentPrice);
        // }
      } else {
        for (let index = 0; index < Veeam365Contract.DiskBand.length; index++) {
          const element = Veeam365Contract.DiskBand[index];
          if (userSlideQuantity <= JSON.parse(element.Start)) {
            userCost = userSlideQuantity * element.Price;
            averageUserPrice = element.Price;
            break;
          }
        }
        if (userSlideQuantity === 0) {
          //setAmount(0);
          setTotal(0);
        } else {
          // var disc = (1 - (Number(contractLength) / 100));
          //var amount = Number(userCost / userSlideQuantity);
          //setAmount((amount * disc).toFixed(3));
          // userCost = (userCost * disc).toFixed(2);
          setUserPrice(averageUserPrice);
        }
      }

      console.log("userCost:", userCost);
      console.log("studentCost:", studentCost);
      setTotal(Number(userCost + studentCost).toFixed(2));
    }
  };

  const calculatePAYGPrice = (paygUserValue, dataProtectValue) => {
    var dataProtectCost = 0;
    var slideUserQuantity = paygUserValue;
    var userCost = 0;
    userCost = slideUserQuantity * Veeam365.server.PerPrice;
    var slideDataQuantity = dataProtectValue;
    for (let index = 0; index < Veeam365.DiskBand.length; index++) {
      const element = Veeam365.DiskBand[index];
      if (slideDataQuantity <= JSON.parse(element.Start)) {
        dataProtectCost = slideDataQuantity * element.Price;
        setAveragePrice(element.Price);
        break;
      }
    }

    // if (slideUserQuantity === 0) {
    //   setAmount(0);
    //   setTotal(0);
    // }
    // else {
    //var disc = (1 - (Number(contractLength) / 100));
    //var amount = Number(cost / slideUserQuantity);
    //setAmount((amount * disc).toFixed(3));
    setTotal((userCost + dataProtectCost).toFixed(2));
    // }
  };
  const handleContractUserSliderChange = (event, newValue) => {
    setContractUserValue(newValue);

    calculatePrice(newValue, contractStudentValue, 0);
  };

  const handleContractUserChange = (event) => {
    var targetValue =
      event.target.value === "" ? 0 : Number(event.target.value);
    if (targetValue > 500) {
      targetValue = 500;
    }
    setContractUserValue(targetValue);
    calculatePrice(targetValue, contractStudentValue, 0);
  };

  const handleContractStudentSliderChange = (event, newValue) => {
    setContractStudentValue(newValue);
    calculatePrice(contractUserValue, newValue, 0);
  };

  const handleContractStudentChange = (event) => {
    var targetValue =
      event.target.value === "" ? 0 : Number(event.target.value);
    if (targetValue > 500) {
      targetValue = 500;
    }
    setContractStudentValue(targetValue);
    calculatePrice(contractUserValue, targetValue, 0);
  };

  const handlePaygUserSliderChange = (event, newValue) => {
    setPaygUserValue(newValue);
    calculatePAYGPrice(newValue, dataProtectValue);
  };

  const handlePaygUserChange = (event) => {
    var targetValue =
      event.target.value === "" ? 0 : Number(event.target.value);
    if (targetValue > 500) {
      targetValue = 500;
    }
    setPaygUserValue(targetValue);
    calculatePAYGPrice(targetValue, dataProtectValue);
  };

  const handleDataProtectSliderChange = (event, newValue) => {
    setDataProtectValue(newValue);
    calculatePAYGPrice(paygUserValue, newValue);
  };

  const handleDataProtectChange = (event) => {
    var targetValue =
      event.target.value === "" ? 0 : Number(event.target.value);
    if (targetValue > 10000) {
      targetValue = 10000;
    }
    setDataProtectValue(targetValue);
    calculatePAYGPrice(paygUserValue, targetValue);
  };

  function valuetext(value) {
    return `${value}`;
  }

  const userMarks = [
    {
      value: 0,
      label: "0 Users",
    },
    {
      value: 500,
      label: "500 Users",
    },
  ];

  const FacultyMarks = [
    {
      value: 0,
      label: "0 Faculty",
    },
    {
      value: 500,
      label: "500 Faculties",
    },
  ];

  const studentsMarks = [
    {
      value: 0,
      label: "0 Student",
    },
    {
      value: 500,
      label: "500 Students",
    },
  ];

  const dataMarks = [
    {
      value: 0,
      label: "0 GB",
    },
    {
      value: 10000,
      label: "10000 GB",
    },
  ];

  const onChangeValue = (event) => {
    clearData();
    const targetValue = event.target.value;
    if (targetValue === "contract") {
      setIsContract(true);
    } else {
      setIsContract(false);
    }
  };

  const OnChangeEducationalUser = (event) => {
    clearData();
    const targetValue = event.target.value;
    if (targetValue === "yes") {
      setIsEducationalUser(true);
    } else {
      setIsEducationalUser(false);
    }
  };
  const renderTooltip = (props) => (
    <Tooltip id={`tooltip-${props.id}`} {...props}>
      {props.text}
    </Tooltip>
  );
  return (
    <>
      <Row className="p-3 pe-4" style={{ borderRadius: "8px" }}>
        <Col sm={12}>
          <Microsoft365Pricing
            isBlurred={props.isBlurred}
            hideRRP={props.hideRRP}
            handleShow={props.handleShow}
            selectedPlan={props.selectedPlan}
            resellerId={props.resellerId}
            displayOnly={props.displayOnly}
            collapsed={props.collapsed}
            planDetails={props.planDetails}
            showLoader={props.showLoader}
          />
        </Col>
      </Row>
      <Box className="p-3 pt-0 scaleup">
        <div
          className="bg-white p-3"
          style={{
            borderRadius: "8px",
            border: "1px solid #5091ce",
            textAlign: "left",
          }}
        >
          <div className="col-12 justify-content-start d-flex">
            <span className="text-muted me-1">
              * Click{" "}
              <a
                href="#"
                style={{ fontSize: "13px" }}
                className="text-primary text-decoration-underline me-1"
                onClick={props.handleRequestShow}
              >
                HERE
              </a>{" "}
              to claim a 14-day trial of CloudCover 365.
            </span>
          </div>
          <div className="col-12 justify-content-start d-flex">
            <span className="text-muted">
              * This pricing is intended as a guide, and we are happy to discuss
              your requirements in more detail.
            </span>
          </div>
          <div className="col-12 justify-content-start d-flex">
            <span className="text-muted me-1">
              * For full service terms and conditions click{" "}
              <a
                href="https://www.virtualdcs.co.uk/downloads"
                style={{ fontSize: "13px" }}
                className="text-primary text-decoration-underline"
              >
                {" "}
                HERE.
              </a>
            </span>
          </div>
          <div className="col-12 justify-content-start d-flex">
            <span className="text-muted me-1">
              * For full service terms and conditions click{" "}
              <a
                href="https://www.virtualdcs.co.uk/downloads"
                style={{ fontSize: "13px" }}
                className="text-primary text-decoration-underline"
              >
                {" "}
                HERE.
              </a>
            </span>
          </div>
          <div className="col-12 justify-content-start d-flex">
            <span className="text-muted">
              * Please note: Shared Mailboxes are not charged as part of the
              service.
            </span>
          </div>
        </div>
      </Box>
    </>
  );
};

export default Microsoft365Backup;
